@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.hero {
  background: url('/assets/images/EF_Revolution_Hero_Landingpage.jpg');
  height: 100%;
}

button {
  height: 48px;
}


customer-portal-revolution-app-error-view {
  height:100%
}